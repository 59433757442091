<template>
  <b-card no-body>
    <validation-observer v-slot="{ handleSubmit }" ref="VForm">
      <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
            </b-col>
          </b-row>
        </b-card-header>
  
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <label>Category (ID)<span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.mjc_category_id" placeholder="e.g Teknologi"></b-form-input>                  
                <VValidate 
                  name="Category (ID)" 
                  v-model="row.mjc_category_id" 
                  :rules="mrValidation.mjc_category_id" 
                />
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <label>Category (EN)<span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.mjc_category_en" placeholder="e.g Technology"></b-form-input>                  
                <VValidate 
                  name="Category (EN)" 
                  v-model="row.mjc_category_en" 
                  :rules="mrValidation.mjc_category_en" 
                />
              </b-form-group>
            </b-col>
  
            <div class="w-100"></div>
  
            <b-col lg="6">
              <b-form-group class="mt-3">
                <label>Status<span class="text-danger mr5">*</span></label>
                <b-form-radio-group
                  :options="Config.mr.StatusOptions"
                  v-model="row.mjc_is_active"
                />
                <VValidate 
                  name="Status" 
                  v-model="row.mjc_is_active" 
                  :rules="{required:1}" 
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
              <b-button type="submit" variant="primary" class="btn-rounded" @click="$parent.doSubmitCRUD('VForm', $refs)">Save Changes</b-button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </b-card>
  </template>
  
  <script>
  const _ = global._
  
  export default{
    props:{
      row:Object,
      mrValidation:Object,
      Config:Object,
      mrMenuRel:Array,
    },
    data() {
      return {
      }
    },
    computed:{
      isEmptyHakAkses(){
        if(!(this.row.bul_menu_role||[]).length) return true
        const dtEmpty = _.filter(this.row.bul_menu_role, v=>{
          return !Object.keys(v).length
        })
        return !!dtEmpty.length
      }
    },
    methods:{
      doSubmitForm(){
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
      isEqual(p1, p2){
        if(p1 == p2) return true
        return false
      },
      setOverflowAuto(){
        document.querySelector('.page-wrapper').style.overflow = 'auto'
      },
      setOverflowHidden(){
        document.querySelector('.page-wrapper').style.overflow = 'hidden'
      },
    }
  }
  </script>